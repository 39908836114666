exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-case-studies-detail-js": () => import("./../../../src/templates/case-studies-detail.js" /* webpackChunkName: "component---src-templates-case-studies-detail-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-clients-js": () => import("./../../../src/templates/clients.js" /* webpackChunkName: "component---src-templates-clients-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-our-approach-detail-js": () => import("./../../../src/templates/our-approach-detail.js" /* webpackChunkName: "component---src-templates-our-approach-detail-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-web-future-mapping-js": () => import("./../../../src/templates/web-future-mapping.js" /* webpackChunkName: "component---src-templates-web-future-mapping-js" */),
  "component---src-templates-web-industry-js": () => import("./../../../src/templates/web-industry.js" /* webpackChunkName: "component---src-templates-web-industry-js" */),
  "slice---src-components-others-case-studies-case-studies-detail-js": () => import("./../../../src/components/others/caseStudies/CaseStudiesDetail.js" /* webpackChunkName: "slice---src-components-others-case-studies-case-studies-detail-js" */)
}

