module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-switcher/gatsby-browser.js'),
      options: {"plugins":[],"defaultDarkTheme":"theme-twitter","defaultLightTheme":"theme-midnightgreen","themeStorageKey":"theme","minify":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://lemon:Lemon.53@cms.lemonhq.io/graphql","ignore":["preview.js"],"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"html":{"useGatsbyImage":false,"generateWebpImages":true,"placeholderType":"none","createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateAvifImages":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lemon Marketing Consultancy Ltd.","short_name":"Lemon","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"content/assets/lemon-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cebb73e3005bd217333b7f9626950ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"title":"Lemon, Marketing, Consultancy","type":"website","locale":"en_US","description":"We're a global network with local insights, and a shared belief in the transformative power of people, technology and data.","images":[{"url":"https://cms.lemonhq.io/wp-content/uploads/2022/12/metaimage.png","alt":"Lemon"}],"site_name":"Lemon, Marketing, Consultancy"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-QTMQYWZW7H","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"hotjar":{"hjid":"3305184","cookieName":"gatsby-gdpr-hotjar"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
